import React from "react";
import "./About.css";
import "../project_samples/Projects.css";
import useScreenSize from "../useScreenSize";
import Footer from "../components/footer/Footer";

import MDB from "../image/Julio_Daniel_DBDeveloper_Certificate.png";
import JAMF from "../image/JAMFCertificate.png";

const About = () => {
  const isMobile = useScreenSize("(max-width: 768px)");
  console.log(isMobile);

  return (
    <>
      <div className="Content">
        <div className={isMobile ? "upper-section" : "upper-section-mobile"}>
          <h1 className="title-heading">About Me</h1>
          <div className="project-sample-container">
            <div className="image-container2">
              <a
                href="https://university.mongodb.com/certification/certificate/470238744"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={MDB} alt="profilePic" />
              </a>
            </div>
            <h1 className="paragraph">
              I am a web and mobile app developer specializing in creating
              responsive, user-friendly applications. As a full-stack developer,
              I bring ideas to life by managing the entire development process.
            </h1>
          </div>
          <div className="project-sample-container">
            <div className="image-container2">
              <img src={JAMF} alt="profilePic" />
            </div>
            <h1 className="paragraph">
              As a JAMF System Administrator I specialize in Mobile Device
              Management (MDM). I develop comprehensive migration plans for JAMF
              and software applications to enhance endpoint security. I oversee
              MacOS and Windows imaging processes using JAMF and Intune to
              streamline employee onboarding.
            </h1>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
